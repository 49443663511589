import React from "react";
import Layout from "../components/shared/layout";
import { StaticQuery, graphql } from "gatsby";
import {
  Hero,
  RecentArticles,
  RecentWikiEdits,
  RecentBlogs,
} from "../components/home";
import { SubscribeForm } from "../components/subscribe";

const IndexPage = ({ title, description }) => (
  <Layout
    newsletterContent={
      <>
        <h2>
          Master the <span className="special-green">essential 80-20</span>.
          Learn to write scalable, testable software.
        </h2>
        <p>
          Actionable tips. Join 15,000+ other value-creating React, TypeScript &
          Node.js devs mastering what matters & creating new opportunities.
        </p>
      </>
    }
    seo={{
      title,
      exactTitle: true,
      description,
      keywords: [
        "advanced",
        "typescript",
        "nodejs",
        "best practices",
        "javascript",
      ],
    }}
  >
    <div className="home">
      <Hero />
      <br />
      <RecentArticles />
      {/* <SolidBookResourceCard/> */}
      <br />
      <hr />
      <br />
      <RecentBlogs />
      <br />
      <hr />
      <RecentWikiEdits />
      <br />
      <SubscribeForm />
      <br />
    </div>
  </Layout>
);

export default () => (
  <StaticQuery
    query={graphql`
      query IndexPageQuery {
        site {
          siteMetadata {
            title
            description
            twitter
            author
            logo
            siteUrl
          }
        }
      }
    `}
    render={(data) => {
      const { siteMetadata } = data.site;
      return <IndexPage {...siteMetadata} />;
    }}
  />
);
